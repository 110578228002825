<template>
  <div class="login-wrap">
<!--      <div class="login_banner">-->
<!--        <img-->
<!--          src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/vue_img/login-top-img.png"-->
<!--        />-->
<!--      </div>-->
     <h2 class="enquiry">Enquiry Form</h2>
    <p class="manner">Please fill this form in a decent manner</p>
    <p class="manner" style="text-align: left;line-height: 0.8rem;margin-top: 20px;background:#f5f5f5;">We are looking for partners around the world who can start the development, production and distribution of standard and biological products.</p>

    <div class="form-wapper">

        <div class="form">
          <div class="form-item">
            <label for="account">
              <span style="font-size: 14px">First Name <span style="color:#df0024;vertical-align: sub">*</span></span>
              <div class="form-label">
                <input
                        placeholder="Please enter your first name"
                        id="firstname"
                        v-model="firstname"
                        type="text"
                />
              </div>
            </label>
          </div>
          <div class="form-item">
            <label for="password">
              <span style="font-size: 14px">Last Name <span style="color:#df0024;vertical-align: sub">*</span></span>
              <div class="form-label">
                <input
                  placeholder="Please enter your last name"
                  id="lastname"
                  v-model="lastname"
                  type="text"
                />
              </div>
            </label>
          </div>
          <div class="form-item">
            <label for="password">
              <span style="font-size: 14px">Email <span style="color:#df0024;vertical-align: sub">*</span></span>
              <div class="form-label">
                <input
                        placeholder="Please enter your email"
                        id="email"
                        v-model="email"
                        type="text"
                />
              </div>
            </label>
          </div>
          <div class="form-item">
            <label for="password">
              <span style="font-size: 14px">Contact</span>
              <div class="form-label">
                <input
                        placeholder="Please enter your contact"
                        id="contact"
                        v-model="contact"
                        type="text"
                />
              </div>
            </label>
          </div>
          <div class="form-item">
            <label for="password">
              <span style="font-size: 14px">Company</span>
              <div class="form-label">
                <input
                        placeholder="Please enter your company"
                        id="comparny"
                        v-model="comparny"
                        type="text"
                />
              </div>
            </label>
          </div>
          <div class="form-item">
            <label for="password">
              <span style="font-size: 14px">Subject</span>
              <div class="form-label">
                <input
                        placeholder="Please enter your subject"
                        id="subjoct"
                        v-model="subjoct"
                        type="text"
                />
              </div>
            </label>
          </div>
          <div class="form-item">
            <label for="password">
              <span style="font-size: 14px">Enquiry</span>
              <div class="form-label">
                <input
                        placeholder="Please enter your enquiry"
                        id="enquiry"
                        v-model="enquiry"
                        type="text"
                />
              </div>
            </label>
          </div>
        </div>
        <div
          id="pass-login"
          class="submit"
          @click="handsendMessage"
        >
          SEND MESSAGE
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  inject,
  reactive,
  onMounted,
  nextTick,
  computed,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import fixedHeader from "@/components/common/Header.vue";
import Toast from "@/components/UI/Toast";
import api from "@/api/axios";
import $ from "jquery";


export default defineComponent({
  name: "contact",
//   components: {
//     fixedHeader,
//   },
  setup() {
      const firstname = ref("");
      const lastname = ref("");
      const email = ref("");
      const contact = ref("");
      const comparny = ref("");
      const subjoct = ref("");
      const enquiry = ref("");

    function handsendMessage() {
      if (firstname.value.length < 1) {
        Toast({
          title: "Please enter your first name",
          type: "warning",
        });
        $("#firstname").focus();
        return false;
      }
      if (lastname.value.length < 1) {
        Toast({
          title: "Please enter your last name",
          type: "warning",
        });
        $("#lastname").focus();
        return false;
      }
      if (email.value.length < 1) {
        Toast({
          title: "Please enter your email",
          type: "warning",
        });
        $("#email").focus();
        return false;
      }
      const formData = new FormData();
      formData.append("firstname", firstname.value);
      formData.append("lastname", lastname.value);
      formData.append("email", email.value);
      formData.append("contact", contact.value);
      formData.append("comparny", comparny.value);
      formData.append("subjoct", subjoct.value);
      formData.append("enquiry", enquiry.value);
      api
              .post("/M/Server/DoSubmitContact", formData)
              .then((res) => {
                if (res.data.success) {
                  Toast({
                    title: res.data.msg,
                    type: "success",
                    onClose: () => {
                      // router.push("/product");
                    },
                  });
                  firstname.value = "";
                  lastname.value = "";
                  email.value = "";
                  contact.value = "";
                  comparny.value = "";
                  subjoct.value = "";
                  enquiry.value = "";
                } else {
                  Toast({
                    title: res.data.msg,
                    type: "warning",
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
    }

    return {
      firstname,
      lastname,
      email,
      contact,
      comparny,
      subjoct,
      enquiry,
      handsendMessage,
    };
  },
});
</script>

<style lang="scss" scoped>
  .enquiry{
    font-size: 30px;
    text-align: center;
    margin:0;
    margin-top:15px;
  }
  .manner{
    width:90%;
    margin:0 auto;
    text-align: center;
    font-size: 16px;
    line-height: 45px;
  }
.login_banner img {
  width: 10rem;
}

.title-ul {
  overflow: hidden;
}

.title-ul li {
  float: left;
  position: relative;
  width: 50%;
  height: 0.867rem;
  line-height: 0.867rem;
  text-align: center;
  color: #999999;
  font-size: 0.4rem;
}

.title-ul .active {
  color: #444;
}

.title-ul .active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0.267rem;
  height: 0.04rem;
  background-color: #d64b4b;
  margin-left: -0.133rem;
  opacity: 1;
}

.form-wapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  /*padding-top: 0.773rem;*/
}

.form-label {
  width: 100%;
  height: 1rem;
  line-height: 1.2rem;
  background-color: #f4f4f4;
  border-radius: 0.067rem;
  padding-left: 0.4rem;
  box-sizing: border-box;
  /*margin-bottom: 0.587rem;*/
}

.form-label input {
  outline: none;
  border: none;
  width: 100%;
  height: 1rem;
  line-height: 1.2rem;
  vertical-align: top;
  background-color: transparent;
}
.code-item {
  position: relative;
  padding-right: 3.467rem;
  box-sizing: border-box;
}
.code-item button {
  position: absolute;
  right: 0;
  top: 0.32rem;
  width: 2.4rem;
  height: 0.8rem;
  border-radius: 0.067rem;
  border: solid 0.027rem #d64b4b;
  line-height: 0.8rem;
  text-align: center;
  color: #d64b4b;
  font-size: 0.373rem;
  background: none;
  outline: none;
  display: block;
}

.code-pic {
  position: relative;
  padding-right: 3.467rem;
  box-sizing: border-box;
}

.code-pic img {
  position: absolute;
  right: 0;
  top: 0.32rem;
  width: 2.4rem;
  height: 0.8rem;
  border-radius: 0.067rem;
  // border: solid 0.027rem #d64b4b;
  line-height: 0.8rem;
  text-align: center;
  color: #d64b4b;
  font-size: 0.373rem;
  background: none;
  outline: none;
  display: block;
}

.submit {
  margin-top: 20px;
  display: block;
  width: 100%;
  height: 1rem;
  background-color: #d64b4b;
  box-shadow: 0 0.133rem 0.267rem 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.133rem;
  line-height: 1rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.4rem;
}

.forget-password {
  margin-top: 0.267rem;
  color: red;
  float: right;
  margin-left: 0.533rem;
  font-size: 0.32rem;
}
.login-wrap {
  height: 100%;
  width: 100%;
}
.login-transform {
  transform: translateX(0);
}

.weixin-login {
  width: 45%;
}
.submit-weixin {
  display: flex;
  justify-content: space-between;
}

.login-enter-active,
.login-leave-active {
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login-enter-from,
.login-leave-to {
  transform: translateX(100%);
}
</style>
