
import {
  defineComponent,
  ref,
  inject,
  reactive,
  onMounted,
  nextTick,
  computed,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import fixedHeader from "@/components/common/Header.vue";
import Toast from "@/components/UI/Toast";
import api from "@/api/axios";
import $ from "jquery";


export default defineComponent({
  name: "contact",
//   components: {
//     fixedHeader,
//   },
  setup() {
      const firstname = ref("");
      const lastname = ref("");
      const email = ref("");
      const contact = ref("");
      const comparny = ref("");
      const subjoct = ref("");
      const enquiry = ref("");

    function handsendMessage() {
      if (firstname.value.length < 1) {
        Toast({
          title: "Please enter your first name",
          type: "warning",
        });
        $("#firstname").focus();
        return false;
      }
      if (lastname.value.length < 1) {
        Toast({
          title: "Please enter your last name",
          type: "warning",
        });
        $("#lastname").focus();
        return false;
      }
      if (email.value.length < 1) {
        Toast({
          title: "Please enter your email",
          type: "warning",
        });
        $("#email").focus();
        return false;
      }
      const formData = new FormData();
      formData.append("firstname", firstname.value);
      formData.append("lastname", lastname.value);
      formData.append("email", email.value);
      formData.append("contact", contact.value);
      formData.append("comparny", comparny.value);
      formData.append("subjoct", subjoct.value);
      formData.append("enquiry", enquiry.value);
      api
              .post("/M/Server/DoSubmitContact", formData)
              .then((res) => {
                if (res.data.success) {
                  Toast({
                    title: res.data.msg,
                    type: "success",
                    onClose: () => {
                      // router.push("/product");
                    },
                  });
                  firstname.value = "";
                  lastname.value = "";
                  email.value = "";
                  contact.value = "";
                  comparny.value = "";
                  subjoct.value = "";
                  enquiry.value = "";
                } else {
                  Toast({
                    title: res.data.msg,
                    type: "warning",
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
    }

    return {
      firstname,
      lastname,
      email,
      contact,
      comparny,
      subjoct,
      enquiry,
      handsendMessage,
    };
  },
});
